/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";

import { Login } from "./pages/LoginPage/Loadable";
import { LogoutPage } from "./pages/LogoutPage/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import { HomePage } from "./pages/HomePage/Loadable";
import { BITESHomePage } from "./pages/BITESHomePage/Loadable";
import { BITESHomePageSearchJob } from "./pages/BITESHomePageSearchJob/Loadable";
import { LandingPage } from "./pages/LandingPage/Loadable";
import { AboutUs } from "./pages/AboutUs/Loadable";
import { TermsAndConditions } from "./pages/TermsAndConditions/Loadable";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/Loadable";
import { CancellationRefund } from "./pages/CancellationRefund/Loadable";
import { ShipppingDelivery } from "./pages/ShipppingDelivery/Loadable";
import { ContactUs } from "./pages/ContactUs/Loadable";

// Student Flow
import { StudentDashboard } from "./pages/StudentDashboard/Loadable";
import { MES } from "./pages/StudentMES/Loadable";
import { StudentSearchJob } from "./pages/StudentSearchJob/Loadable";
import { StudentJobDetail } from "./pages/StudentJobDetail/Loadable";
import { StudentProfile } from "./pages/StudentProfile/Loadable";
import { StudentCommunity } from "./pages/StudentCommunity/Loadable";
import { StudentAllCommunity } from "./pages/StudentAllCommunity/Loadable";
import { StudentMyApplication } from "./pages/StudentMyApplications/Loadable";
import { StudentFavourites } from "./pages/StudentFavourites/Loadable";
import { StudentNotifications } from "./pages/StudentNotifications/Loadable";
import { StudentResumeTemplates } from "./pages/StudentResumeTemplates/Loadable";
import { AddTranscript } from "./pages/StudentProfile/TranscriptLoadable";

// Campus Flow
import { CampusNotifications } from "./pages/CampusNotifications/Loadable";
import { CampusDashboard } from "./pages/CampusDashboard/Loadable";
import { CampusStudents } from "./pages/CampusStudents/Loadable";
import { CampusProfile } from "./pages/CampusProfile/Loadable";
import { CampusBulkUpload } from "./pages/CampusBulkUpload/Loadable";
import { CampusIndustryConnect } from "./pages/CampusIndustryConnect/Loadable";
import { CampusFacultyDevelopment } from "./pages/CampusFacultyDevelopment/Loadable";
import { CampusIndustryImmersionProgram } from "./pages/CampusIndustryImmersionProgram/Loadable";
import { CampusIndustryConsultingProject } from "./pages/CampusIndustryConsultingProject/Loadable";
import { CampusAddEditStudents } from "./pages/CampusAddEditStudents/Loadable";
import { CampusKYC } from "./pages/CampusKYC/Loadable";
import { CampusStudentActivity } from "./pages/CampusStudentActivity/Loadable";
import { CampusServiceOfferings } from "./pages/CampusServiceOfferings/Loadable";
import { CampusIndustryConnectViewAllJobs } from "./pages/CampusIndustryConnectViewAllJobs/Loadable";

// Employer Flow
import { CompanyProfile } from "./pages/EmployerProfile/Loadable";
import { EmployerDashBoard } from "./pages/EmployerDashboard/Loadable";
import { MatchProfile } from "./pages/StudentMatchProfile/Loadable";
import { EmployerInternshipDetails } from "./pages/EmployerInternshipDetails/Loadable";
import { ViewInternshipDetails } from "./pages/EmployerViewInternshipDetails/Loadable";
import { EmployerAppliedCandidates } from "./pages/EmployerAppliedCandidates/Loadable";
import { EmployerCollegeConnect } from "./pages/EmployerCollegeConnect/Loadable";
import { EmployerCollegeStudentList } from "./pages/EmployerCollegeStudentList/Loadable";
import { EmployerCollegeList } from "./pages/EmployerCollegeList/Loadable";
import { AddEmployerJobs } from "./pages/EmployerAddJobs/Loadable";
import { EmployerJobDetails } from "./pages/EmployerJobsDetails/Loadable";
import { EmployerJobDraft } from "./pages/EmployerJobDraft/Loadable";
import { EmployerJobViewDetails } from "./pages/EmployerJobDetails";
import { EmployerJobAppliedCandidate } from "./pages/EmployerJobAppliedCandidates/Loadable";
import { EmployerSearchCandidates } from "./pages/EmployerSearchCandidates/Loadable";
import { EmployerKYC } from "./pages/EmployerKYC/Loadable";
import { EmployerNotifications } from "./pages/EmployerNotifications/Loadable";
import { SetPassword } from "./pages/SetPasswordPage/Loadable";

// Admin Flow
import { AdminEntities } from "./pages/AdminEntities/Loadable";
import { AdminNewRegistrations } from "./pages/AdminNewRegistrations/Lodable";
import { AdminCommunities } from "./pages/AdminCommunities/Loadable";

import AppApolloprovider from "./ApolloProvider";
import { useTranslation } from "react-i18next";
import getStore from "store/zustandStore";
import { Backdrop, CircularProgress } from "@mui/material";
import ResumeTemplate from "./components/ResumeTemplate";
import TranscriptsDetails from "./components/TranscriptsDetails";
import ProtectedRoute from "./ProtectedRoute";
import { StudentProfileCompletion } from "./pages/StudentProfile/LoadableProfileCompletion";
import { ForgotPassword } from "./pages/ForgotPassword/Loadable";
import { StudentRegistration } from "./pages/StudentRegistration/Loadable";
import { CollegeRegistration } from "./pages/CollegeRegistration/Loadable";
import { CompanyRegistration } from "./pages/CompanyRegistration/Loadable";
import { StudentResumeTemplate } from "./pages/StudentResumeAddEdit/Loadable";
import { StudentMESPsychometricDetails } from "./pages/StudentMESPsychometricDetails/Loadable";
import { StudentMESAptitudeDetails } from "./pages/StudentMESAptitudeDetails/Loadable";
import { StudentMESAcademicDetails } from "./pages/StudentMESAcademicDetails/Loadable";
import { NoInternetPage } from "./components/NoInternetPage/Loadable";
import { UnderMaintenancePage } from "./components/UnderMaintenancePage/Loadable";

export function App() {
  const { i18n } = useTranslation();
  const loading = getStore((state: any) => state.loading);
  const [online, setOnline] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener("online", function () {
      setOnline(true);
    });

    window.addEventListener("offline", function () {
      setOnline(false);
    });
  }, []);

  if (!online) {
    return <NoInternetPage />;
  }

  return (
    <BrowserRouter>
      <Helmet
        // titleTemplate="%s - React Boilerplate"
        defaultTitle="Prosculpt Campus Connect"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Prosculpt jobs" />
      </Helmet>
      <AppApolloprovider>
        <Switch>
          {/* <Route exact path="/" component={HomePage} /> */}
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/log-out" component={LogoutPage} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/setpassword" component={SetPassword} />
          <Route
            exact
            path="/student-registration"
            component={StudentRegistration}
          />
          <Route
            exact
            path="/college-registration"
            component={CollegeRegistration}
          />
          <Route
            exact
            path="/company-registration"
            component={CompanyRegistration}
          />
          <Route exact path="/search-jobs" component={BITESHomePageSearchJob} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/cancellation-refund"
            component={CancellationRefund}
          />
          <Route
            exact
            path="/shipping-delivery"
            component={ShipppingDelivery}
          />
          <Route exact path="/contact-us" component={ContactUs} />

          {/* Student Flow */}
          <ProtectedRoute
            exact
            path="/student-dashboard"
            component={StudentDashboard}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-search-jobs-and-internships"
            component={StudentSearchJob}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-job-detail/:jobId"
            component={StudentJobDetail}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-mafoi-employability-score"
            component={MES}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-mes-aptitude-details/:assessmentId"
            component={StudentMESAptitudeDetails}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-mes-psychometric-details/:assessmentId"
            component={StudentMESPsychometricDetails}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-mes-academic-details/:assessmentId"
            component={StudentMESAcademicDetails}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-match-profile/:jobId"
            component={MatchProfile}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-profile"
            component={StudentProfile}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-profile-completion"
            component={StudentProfileCompletion}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-events"
            component={StudentCommunity}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-allcommunity"
            component={StudentAllCommunity}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-myapplication"
            component={StudentMyApplication}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-favourites"
            component={StudentFavourites}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-notifications"
            component={StudentNotifications}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-add-transcript/:index"
            component={AddTranscript}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-resume-templates"
            component={StudentResumeTemplates}
            authenticationPath="/"
            roles={["student"]}
          />
          <ProtectedRoute
            exact
            path="/student-resume-builder/:templateId"
            component={StudentResumeTemplate}
            authenticationPath="/"
            roles={["student"]}
          />

          {/* Employer Flow */}
          <ProtectedRoute
            exact
            path="/employer-profile"
            component={CompanyProfile}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-dashboard"
            component={EmployerDashBoard}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-internship"
            component={EmployerInternshipDetails}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-internship-details"
            component={ViewInternshipDetails}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-applied-candidates"
            component={EmployerAppliedCandidates}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-job-details"
            component={EmployerJobDetails}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-job-view-details/:jobId"
            component={EmployerJobViewDetails}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-job-appliedcanditate/:jobId"
            component={EmployerJobAppliedCandidate}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-college-connect"
            component={EmployerCollegeConnect}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-college-connect-students-list/:campusId"
            component={EmployerCollegeStudentList}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-college-list/:campusId"
            component={EmployerCollegeList}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-add-job/:jobId"
            component={AddEmployerJobs}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-search-job"
            component={EmployerJobDetails}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-job-draft"
            component={EmployerJobDraft}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-search-candidates"
            component={EmployerSearchCandidates}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-notifications"
            component={EmployerNotifications}
            authenticationPath="/"
            roles={["employer_user"]}
          />
          <ProtectedRoute
            exact
            path="/employer-kyc"
            component={EmployerKYC}
            authenticationPath="/"
            roles={["employer_user"]}
          />

          {/* Resume */}
          <Route
            exact
            path="/resume-template/:studentId"
            component={ResumeTemplate}
          />
          <Route
            exact
            path="/transcripts-details/:studentId"
            component={TranscriptsDetails}
          />

          {/* Campus Flow */}
          <ProtectedRoute
            exact
            path="/campus-dashboard"
            component={CampusDashboard}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-students"
            component={CampusStudents}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-profile"
            component={CampusProfile}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-bulk-update-students"
            component={CampusBulkUpload}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-industry-connect"
            component={CampusIndustryConnect}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-faculty-development"
            component={CampusFacultyDevelopment}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-industry-immersion-program"
            component={CampusIndustryImmersionProgram}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-industry-consulting-project"
            component={CampusIndustryConsultingProject}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-add-edit-student/:studentId"
            component={CampusAddEditStudents}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-kyc"
            component={CampusKYC}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-student-activity"
            component={CampusStudentActivity}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-service-offerings"
            component={CampusServiceOfferings}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-industry-connect-all-jobs/:employerId"
            component={CampusIndustryConnectViewAllJobs}
            authenticationPath="/"
            roles={["college"]}
          />
          <ProtectedRoute
            exact
            path="/campus-notifications"
            component={CampusNotifications}
            authenticationPath="/"
            roles={["college"]}
          />

          {/* Admin Flow */}
          <ProtectedRoute
            exact
            path="/admin-entities"
            component={AdminEntities}
            authenticationPath="/"
            roles={["super_admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin-new-registrations"
            component={AdminNewRegistrations}
            authenticationPath="/"
            roles={["super_admin"]}
          />
          <ProtectedRoute
            exact
            path="/admin-community"
            component={AdminCommunities}
            authenticationPath="/"
            roles={["super_admin"]}
          />
          {/* <Route exact path="/no-internet" component={NoInternetPage} /> */}
          <Route
            exact
            path="/under-maintenance"
            component={UnderMaintenancePage}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </AppApolloprovider>
      <GlobalStyle />
      <Backdrop
        open={loading || false}
        sx={{
          color: "#000000",
          opacity: "70%",
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress />
      </Backdrop>
    </BrowserRouter>
  );
}

import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Paper, Button, Typography, Box } from "@mui/material";
import {
  AccountBoxOutlined,
  LocationOnOutlined,
  ModeEditOutlineOutlined,
  PeopleAltOutlined,
  BusinessCenterOutlined,
  PaymentsOutlined,
  SchoolOutlined,
  VisibilityOutlined,
  FiberManualRecord,
} from "@mui/icons-material";
import EmployerNavbar from "app/components/EmployerNavbar";
import JobDetails from "./JobDetails";
import { colors } from "styles/colors";

import { useQuery, useMutation } from "@apollo/client";
import { GET_JOB, UPDATE_JOB } from "app/graphql/jobs";
import getStore from "store/zustandStore";
import { statusMapping } from "utils/constants";

const JobViewDetails = () => {
  const history = useHistory();
  const uriParams: any = useParams();
  const setLoading = getStore((state: any) => state.setLoading);

  const [jobDetails, setJobDetails] = React.useState<any>({});

  const { data: jobData, loading } = useQuery(GET_JOB, {
    variables: { id: uriParams?.jobId },
    skip: !uriParams?.jobId,
  });

  React.useEffect(() => {
    if (jobData && jobData?.job) {
      setJobDetails(jobData?.job);
    }
  }, [jobData]);

  React.useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <Grid container spacing={2} sx={{ px: { xs: 2, sm: 5, md: 7 } }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Paper elevation={2} sx={{ p: { xs: 2, sm: 3 } }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item lg={7} md={7} sm={6} xs={12}>
              <Typography fontSize="26px">{jobDetails?.title || ""}</Typography>
              <Typography fontSize="16px">
                {jobDetails?.category?.name || ""}
              </Typography>
            </Grid>
            {jobDetails?.status !== 16 && (
              <Grid
                item
                lg={5}
                md={5}
                sm={6}
                xs={12}
                display="flex"
                justifyContent={{ xs: "flex-start", sm: "flex-end" }}
                alignItems="center"
              >
                <Button
                  size="small"
                  variant="outlined"
                  startIcon={
                    <AccountBoxOutlined fontSize="small" color="primary" />
                  }
                  onClick={() =>
                    history.push(
                      `/employer-job-appliedcanditate/${uriParams?.jobId}`
                    )
                  }
                >
                  View Applicants
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={
                    <ModeEditOutlineOutlined
                      fontSize="small"
                      color="secondary"
                    />
                  }
                  sx={{ ml: 1.5 }}
                  onClick={() =>
                    history.push(`/employer-add-job/${uriParams?.jobId}`)
                  }
                >
                  Edit
                </Button>
              </Grid>
            )}
            <Grid
              item
              lg={8}
              md={8}
              sm={6}
              xs={12}
              display="flex"
              alignItems="center"
              sx={{ my: { xs: 0, sm: 2 } }}
            >
              <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                <LocationOnOutlined fontSize="small" color="primary" />
                <Typography fontSize="13px" color={colors.fontLight}>
                  {jobDetails?.city?.name || ""}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                <PeopleAltOutlined fontSize="small" color="primary" />
                <Typography fontSize="13px" color={colors.fontLight}>
                  {jobDetails?.positions || "0"} positions
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                <BusinessCenterOutlined fontSize="small" color="primary" />
                <Typography fontSize="13px" color={colors.fontLight}>
                  {jobDetails?.minimumExperience || ""} to{" "}
                  {jobDetails?.maximumExperience || ""} years
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                <PaymentsOutlined fontSize="small" color="primary" />
                <Typography fontSize="13px" color={colors.fontLight}>
                  ₹{jobDetails?.minimumRemuneration || ""} to ₹
                  {jobDetails?.maximumRemuneration || ""}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <SchoolOutlined fontSize="small" color="primary" />
                <Typography fontSize="13px" color={colors.fontLight}>
                  {jobDetails?.qualification?.name || ""}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent={{ xs: "flex-start", sm: "flex-end" }}
              sx={{ my: { xs: 0, sm: 2 } }}
            >
              <FiberManualRecord
                fontSize="small"
                sx={{
                  color:
                    jobDetails?.status === 1 ? colors.success : colors.danger,
                }}
              />
              <Typography
                fontSize="12px"
                color={colors.fontLight}
                sx={{ ml: 0.5 }}
              >
                {jobDetails?.status ? statusMapping[jobDetails?.status] : ""}
              </Typography>
              <Typography color={colors.fontLight} sx={{ mx: 1 }}>
                ·
              </Typography>
              <Box display="flex" alignItems="center">
                <VisibilityOutlined
                  fontSize="small"
                  sx={{ color: colors.fontLighter }}
                />
                <Typography
                  fontSize="12px"
                  color={colors.fontLight}
                  sx={{ ml: 0.5 }}
                >
                  {jobDetails?.numberOfViews} View(s)
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <JobDetails data={jobDetails} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* {jobDetails?.status !== 16 && (
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="flex-end"
        >
          <Button size="small" variant="contained" color="error">
            Close Job
          </Button>
        </Grid>
      )} */}
    </Grid>
  );
};

export const EmployerJobViewDetails = (props) => {
  return <EmployerNavbar mainComponent={<JobViewDetails />} />;
};

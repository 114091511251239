import * as React from "react";
import { Chip } from "@mui/material";
import { colors } from "../../styles/colors";

interface IChipsProps {
  label: string;
}

const Chips = (props: IChipsProps) => {
  const { label } = props;
  return (
    <Chip
      label={label}
      sx={{ backgroundColor: colors.primaryFill, color: colors.primary }}
    />
  );
};

export default Chips;

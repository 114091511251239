import * as React from "react";
import { Grid, Typography, Chip } from "@mui/material";
import HorizontalDivider from "app/components/HorizontalDivider";
import { colors } from "styles/colors";
import { genders, jobModes, jobTeamTypes } from "utils/constants";
import { getDateInFormat } from "utils/helpers";
import Chips from "app/components/Chips";

interface ILanguageChipsProps {
  language: string;
  proficiency: any;
}

function LanguageChips(props: ILanguageChipsProps) {
  const { language, proficiency } = props;
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item lg={2} md={2} sm={12} xs={12}>
        <Typography fontSize="14px">{language}</Typography>
      </Grid>
      <Grid item lg={10} md={10} sm={12} xs={12}>
        {proficiency &&
          proficiency.map(
            (prof) =>
              prof.value && (
                <Chip
                  key={prof.prof}
                  label={prof.prof}
                  sx={{
                    mr: 0.5,
                    backgroundColor: "#E2EEF5",
                    color: colors.primary,
                  }}
                />
              )
          )}
      </Grid>
    </Grid>
  );
}

const JobDetails = (props) => {
  const { data } = props;

  const [perks, setPerks] = React.useState([]);
  const [certificates, setCertificates] = React.useState([]);
  const [skills, setSkills] = React.useState([]);

  React.useEffect(() => {
    const temp: any = [];
    if (data && data?.perks) {
      const tempJSON = JSON.parse(data?.perks);
      if (tempJSON && tempJSON.transport) {
        temp.push("Transport Facility");
      }
      if (tempJSON && tempJSON.freeLunch) {
        temp.push("Free/Subisidized Lunch");
      }
      if (tempJSON && tempJSON.freeSnacks) {
        temp.push("Free Snacks");
      }
      if (tempJSON && tempJSON.fiveDayWork) {
        temp.push("5 Days Work Week");
      }
    }
    setPerks(temp);

    if (data && data?.certificates) {
      const tempJSON = JSON.parse(data?.certificates);
      if (tempJSON) {
        setCertificates(tempJSON);
      }
    }

    if (data && data?.skills) {
      const tempJSON = JSON.parse(data?.skills);
      if (tempJSON) {
        setSkills(tempJSON);
      }
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="16px">Job Description</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          {data?.description || ""}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 2 }}>
        <HorizontalDivider />
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Job Type
        </Typography>
        <Typography fontSize="16px">
          {data?.jobMode ? jobModes[data?.jobMode] : ""} /{" "}
          {data?.jobTeamType ? jobTeamTypes[data?.jobTeamType] : ""}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Posted On
        </Typography>
        <Typography fontSize="16px">
          {data?.createdDate
            ? getDateInFormat(data?.createdDate, "DD MMM YYYY")
            : ""}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Last Date to apply
        </Typography>
        <Typography fontSize="16px">
          {data?.closure ? getDateInFormat(data?.closure, "DD MMM YYYY") : ""}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Gender Preference
        </Typography>
        <Typography fontSize="16px">
          {data?.genderPreference ? genders[data?.genderPreference] : ""}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 2 }}>
        <HorizontalDivider />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="16px">Salary Details</Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Salary Amount
        </Typography>
        <Typography fontSize="16px">
          ₹{data?.minimumRemuneration || "0"} to{" "}
          {data?.maximumRemuneration || "-"}
        </Typography>
      </Grid>
      <Grid item lg={9} md={9} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Perks
        </Typography>
        <Typography fontSize="16px">
          {perks.length > 0 ? perks.join(", ") : "-"}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ my: 2 }}>
        <HorizontalDivider />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="16px">Qualification Details</Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Qualification
        </Typography>
        <Typography fontSize="16px">
          {data?.qualification?.name || ""}
        </Typography>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Year of Passing
        </Typography>
        <Typography fontSize="16px">
          {data?.minimumYearOfPassing
            ? getDateInFormat(data?.minimumYearOfPassing, "YYYY")
            : ""}{" "}
          -{" "}
          {data?.maximumYearOfPassing
            ? getDateInFormat(data?.maximumYearOfPassing, "YYYY")
            : ""}
        </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Required Certificates
        </Typography>
        <Typography fontSize="16px">
          {certificates.length > 0 ? certificates.join(", ") : "-"}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Key Skills
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        {skills.length > 0 ? (
          <Grid container spacing={1}>
            {skills.map((skill) => (
              <Grid item>
                <Chips label={skill} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography fontSize="14px" color={colors.fontLight}>
            -
          </Typography>
        )}
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography fontSize="14px" color={colors.fontLight}>
          Languages Known
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: -1 }}>
        {data?.languages && data?.languages.length > 0 ? (
          <Grid container spacing={1}>
            {data?.languages.map((language) => (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <LanguageChips
                  language={language.language}
                  proficiency={[
                    { value: language.read, prof: "Read" },
                    { value: language.write, prof: "Write" },
                    { value: language.speak, prof: "Speak" },
                  ]}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography fontSize="14px" color={colors.fontLight}>
            -
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default JobDetails;

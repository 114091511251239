import * as React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Fade,
  Fab,
  Avatar,
  Paper,
  useScrollTrigger,
  Breadcrumbs,
  Menu,
  MenuItem
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CommonFooter from "./CommonFooter";
import images from "../../assets/images/images";
import { colors } from "../../styles/colors";
import {
  clearSession,
  getEmployerBreadcrumbs,
  getEmployerNavbarSelected,
  getUserInfo
} from "utils/helpers";
import EmployerNotifications from "./EmployerNotificationsMenu";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement;
  mainComponent: React.ReactNode;
  clearNotifications?: boolean;
}

const drawerWidth = 240;
const navItems = [
  { id: 0, name: "Dashboard", path: "/employer-dashboard" },
  { id: 1, name: "Jobs/Internships", path: "/employer-search-job" },
  // { id: 2, name: "Internships", path: "/employer-internship" },
  { id: 2, name: "Applied Candidates", path: "/employer-applied-candidates" },
  { id: 3, name: "Search Candidates", path: "/employer-search-candidates" },
  { id: 4, name: "College Connect", path: "/employer-college-connect" }
];

function ScrollTop(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center"
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const EmployerNavbar = (props: Props) => {
  const { window, mainComponent, clearNotifications } = props;
  const history = useHistory();
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState<
    { id: number; name: string; path: string; color: string }[]
  >([]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
        <img src={images.prosculpt_logo_colored} height={30} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              selected={getEmployerNavbarSelected(location, item)}
              onClick={() => history.push(item.path)}
              sx={{
                borderLeft: getEmployerNavbarSelected(location, item)
                  ? `5px solid ${colors.primary}`
                  : "none"
              }}
            >
              <ListItemText disableTypography>
                <Typography
                  color={
                    getEmployerNavbarSelected(location, item)
                      ? colors.primary
                      : colors.fontLight
                  }
                  fontSize="16px"
                >
                  {item.name}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  React.useEffect(() => {
    setBreadcrumbs(getEmployerBreadcrumbs(location));
  }, [location]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { firstName } = getUserInfo();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" elevation={0} color="primary">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: "block" }}
              onClick={() => history.push("/")}
            >
              <img src={images.prosculpt_logo_white} height={45} alt="logo" />
            </Typography>
            <Box
              sx={{
                display: { xs: "none", sm: "flex" }
              }}
            >
              {navItems &&
                navItems.map((item, index) => (
                  <Typography
                    key={item.id}
                    color={
                      getEmployerNavbarSelected(location, item)
                        ? colors.secondary
                        : `${colors.secondary}50`
                    }
                    fontSize="16px"
                    sx={{ cursor: "pointer", mr: 2 }}
                    onClick={() => {
                      history.push(item.path);
                    }}
                  >
                    {item.name}
                  </Typography>
                ))}
            </Box>
            <EmployerNotifications clearNotifications={clearNotifications} />
            <Avatar
              sx={{
                cursor: "pointer",
                color: colors.primary,
                backgroundColor: colors.secondary
              }}
              onClick={() => history.push("/employer-profile")}
            >
              {firstName?.length > 0 ? firstName[0] : ""}
            </Avatar>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ width: "100%", backgroundColor: colors.secondary }}
        >
          <Toolbar id="back-to-top-anchor" />
          <Paper
            elevation={1}
            sx={{ py: 2, px: { md: 15, sm: 5, xs: 3 }, borderRadius: 0 }}
          >
            <Breadcrumbs separator={">"} aria-label="breadcrumb">
              {breadcrumbs.map((crumb) => (
                <NavLink
                  key={crumb.id}
                  role="button"
                  to={crumb.path}
                  style={{
                    textDecoration: "none",
                    color: crumb.color,
                    fontSize: "16px"
                  }}
                >
                  {crumb.name}
                </NavLink>
              ))}
            </Breadcrumbs>
          </Paper>
          <Box
            sx={{
              py: 3,
              px: { md: 15, sm: 5, xs: 3 }
            }}
          >
            {mainComponent}
          </Box>
          <CommonFooter />
        </Box>
      </Box>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default EmployerNavbar;

import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Divider,
  Grid,
  Typography,
  Avatar,
  Box,
  Button,
} from "@mui/material";
import {
  NotificationsNoneOutlined,
  DoneAllOutlined,
  ArrowForwardOutlined,
} from "@mui/icons-material";
import { colors } from "../../styles/colors";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_NOTIFICATIONS,
  MARK_AS_READ_NOTIFICATIONS,
} from "app/graphql/users";
import moment from "moment";

interface INotificationItemProps {
  content: any;
  history: any;
}

function NotificationItem(props: INotificationItemProps) {
  const { content, history } = props;
  return (
    <MenuItem
      sx={{
        borderLeft: content.unread ? `3px solid ${colors.primary}` : 0,
        backgroundColor: content.unread ? colors.primaryFill : "none",
        borderBottom: "1px solid #E9E9E9",
      }}
      onClick={() => history.push("/employer-notifications")}
    >
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            color="#A4A5A8"
            fontSize="11px"
            display="flex"
            justifyContent="flex-end"
          >
            {content.timestamp ? moment(content.timestamp).fromNow() : ""}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item sx={{ width: "15%" }}>
              <Avatar>{content.verb.charAt(0)}</Avatar>
            </Grid>
            <Grid item sx={{ width: "80%" }}>
              <Typography
                color={colors.fontLight}
                fontSize="14px"
                fontWeight={500}
                noWrap
              >
                {content.verb}
              </Typography>
              <Typography
                fontSize="14px"
                color={`${colors.fontLight}50`}
                noWrap
              >
                {content?.description ? content?.description : content?.verb}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{ width: "5%", display: "flex", justifyContent: "flex-end" }}
            >
              <DoneAllOutlined
                fontSize="small"
                sx={{ color: content.unread ? "#A4A5A8" : colors.primary }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MenuItem>
  );
}

const EmployerNotifications = (props) => {
  const { clearNotifications } = props;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState<any>([]);
  const [count, setCount] = React.useState(0);

  const { data: rawNotifications, refetch } = useQuery(GET_NOTIFICATIONS, {
    variables: {},
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  const [markAsRead] = useMutation(MARK_AS_READ_NOTIFICATIONS, {
    onCompleted(data) {
      const { markAsRead } = data;
      const { status } = markAsRead;
      if (status) {
        refetch();
      }
    },
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (rawNotifications && rawNotifications?.notifications) {
      setNotifications(rawNotifications?.notifications);
      let localUnread = 0;
      rawNotifications?.notifications.map((n: any) => {
        if (n.unread) {
          localUnread++;
        }
      });
      setCount(localUnread);
    } else {
      setNotifications([]);
    }
  }, [rawNotifications]);

  React.useEffect(() => {
    if (clearNotifications) {
      refetch();
    }
  }, [clearNotifications]);

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ mr: 2 }}
      >
        {count > 0 ? (
          <Badge
            badgeContent={count}
            color="secondary"
            sx={{ "& .MuiBadge-badge": { color: colors.primary } }}
          >
            <NotificationsNoneOutlined color="secondary" />
          </Badge>
        ) : (
          <NotificationsNoneOutlined color="secondary" />
        )}
      </IconButton>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ width: { xs: 300, sm: 450 } }}
      >
        {notifications?.length > 0 ? (
          <>
            <Grid container spacing={1} sx={{ px: 2, py: 1.5 }}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography fontSize="16px">Recent Notifications</Typography>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  size="small"
                  startIcon={
                    <DoneAllOutlined fontSize="small" color="primary" />
                  }
                  onClick={() => markAsRead()}
                >
                  Mark all as read
                </Button>
              </Grid>
            </Grid>
            <Divider />
            {notifications &&
              notifications.map((noti, index) => (
                <NotificationItem
                  key={noti.id}
                  content={noti}
                  history={history}
                />
              ))}
            <MenuItem onClick={() => history.push("/employer-notifications")}>
              <Grid container spacing={1} alignItems="center" sx={{ py: 1 }}>
                <Grid item lg={7} md={7} sm={7} xs={7}>
                  <Typography color="primary" fontSize="14px">
                    View All Notifications
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={5}
                  md={5}
                  sm={5}
                  xs={5}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <ArrowForwardOutlined color="primary" fontSize="small" />
                </Grid>
              </Grid>
            </MenuItem>
          </>
        ) : (
          <MenuItem>
            <Typography>No notifications</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default EmployerNotifications;

/**
 * Asynchronously loads the component for AdminCommunities
 */

import { lazyLoad } from "utils/loadable";

export const AdminCommunities = lazyLoad(
  () => import("./index"),
  (module) => module.AdminCommunities
);

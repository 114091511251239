import { gql } from "@apollo/client";

export const ADD_JOB = gql`
  mutation createjob($payload: JobCreateInput!) {
    createJob(payload: $payload) {
      status
      error
      job {
        id
        title
      }
    }
  }
`;

export const UPDATE_JOB = gql`
  mutation updateJob($payload: JobUpdateInput!) {
    updateJob(payload: $payload) {
      status
      error
      job {
        id
        title
      }
    }
  }
`;

export const GET_JOB = gql`
  query job($id: String!) {
    job(id: $id) {
      id
      title
      jobType
      jobMode
      jobTeamType
      description
      category {
        id
        name
      }
      industry {
        id
        name
      }
      qualification {
        id
        name
      }
      courses
      certificates
      skills
      state {
        id
        name
      }
      city {
        id
        name
      }
      country {
        id
        name
      }
      organization
      createdBy {
        id
        username
        organization {
          id
          name
        }
      }
      location
      positions
      salaryConfidential
      minimumRemuneration
      maximumRemuneration
      perks
      minimumExperience
      maximumExperience
      minimumYearOfPassing
      maximumYearOfPassing
      genderPreference
      closure
      startDate
      duration
      confidential
      profile
      createdBy {
        id
        firstName
        lastName
      }
      status
      numberOfViews
      AppliedCount
      ShortlistedCount
      OfferedCount
      RejectedCount
      jobStatus
      isSaved
      modifiedDate
      createdDate
      stipendType
      languages {
        id
        language
        read
        write
        speak
      }
    }
  }
`;

export const GET_JOBS = gql`
  query jobs(
    $filter: JobFilterInput
    $limit: Int
    $page: Int
    $sort: String
    $not_candidate: String
  ) {
    jobs(
      filter: $filter
      limit: $limit
      page: $page
      sort: $sort
      not_candidate: $not_candidate
    ) {
      count
      jobs {
        id
        title
        jobType
        jobMode
        jobTeamType
        description
        category {
          id
          name
        }
        qualification {
          id
          name
        }
        city {
          id
          name
        }
        skills
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        status
        numberOfViews
        createdDate
      }
    }
  }
`;

export const UPDATE_JOB_CANDIDATE_BY_JOB = gql`
  mutation upsertJobCandidate($payload: [JobCandidateUpsertInput!]) {
    upsertJobCandidate(payload: $payload) {
      status
      error
    }
  }
`;

export const UPDATE_JOB_CANDIDATE = gql`
  mutation changeJobCandidateStatus($payload: ChangeJobCandidateStatus!) {
    changeJobCandidateStatus(payload: $payload) {
      status
      error
    }
  }
`;

export const JOB_CANDIDATES = gql`
  query jobcandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
    $sort: String
  ) {
    jobCandidates(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      count
      jobcandidates {
        id
        job {
          id
          title
          city {
            id
            name
          }
        }
        candidate {
          id
          firstName
          lastName
          email
          mobile
          skills
        }
        rating
        status
        comments
        createdDate
        history {
          status
          createdDate
        }
      }
    }
  }
`;

export const JOB_CANDIDATES_COUNT = gql`
  query jobcandidates(
    $filter: JobCandidateFilterInput
    $limit: Int
    $page: Int
    $sort: String
  ) {
    jobCandidates(filter: $filter, limit: $limit, page: $page, sort: $sort) {
      count
    }
  }
`;

export const GET_JOB_CANDIDATE = gql`
  query getJobCandidate($id: String!) {
    jobCandidate(id: $id) {
      id
      candidate {
        id
        firstName
        lastName
        email
        mobile
        dob
        location
        city {
          id
          name
        }
        state {
          id
          name
        }
        gender
        qualification {
          id
          qualification {
            id
            name
          }
          course {
            id
            name
          }
          collegePercentage
          specialization
          graduationYear
        }
        workExp {
          id
          designation
          company
          functionalArea {
            id
            name
          }
          startDate
          endDate
        }
        workPref {
          id
          jobLocation
          functionalArea {
            id
            name
          }
          industry {
            id
            name
          }
          jobState {
            id
            name
          }
          jobCity {
            id
            name
          }
          jobType1
          jobType2
          minimumExpectation
          maximumExpectation
        }
        skills
        languagesKnown {
          id
          language
          read
          write
          speak
        }
        profile
        facultyRecommendation
      }
      comments
      status
      createdDate
      modifiedDate
      history {
        status
        createdDate
      }
      job {
        id
        title
        organization
      }
      interviews {
        id
        startTime
        endTime
        interviewDate
        location
        comments
        status
      }
    }
  }
`;

export const SCHEDULE_INTERVIEW = gql`
  mutation scheduleInterview($payload: InterviewCreateInput!) {
    scheduleInterview(payload: $payload) {
      status
      error
    }
  }
`;

export const EDIT_SCHEDULED_INTERVIEW = gql`
  mutation ediScheduleInterview($payload: InterviewUpdateInput!) {
    updateScheduledInterview(payload: $payload) {
      status
      error
    }
  }
`;

export const DELETE_JOBS = gql`
  mutation deleteJob($ids: [String!]) {
    deleteJob(ids: $ids) {
      status
      error
    }
  }
`;

export const SEARCH_JOBS = gql`
  query searchJobs($filter: JobFilterInput, $limit: Int, $page: Int) {
    searchJobs(filter: $filter, limit: $limit, page: $page) {
      count
      jobs {
        id
        title
        jobType
        description
        city {
          id
          name
        }
        location
        positions
        minimumRemuneration
        maximumRemuneration
        minimumExperience
        maximumExperience
        qualification {
          id
          name
        }
        skills
      }
    }
  }
`;

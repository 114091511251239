/**
 * Asynchronously loads the component for AdminEntities
 */

import { lazyLoad } from "utils/loadable";

export const AdminEntities = lazyLoad(
  () => import("./index"),
  (module) => module.AdminEntities
);
